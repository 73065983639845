import { render, staticRenderFns } from "./AppCategoryItem.vue?vue&type=template&id=bcf6b394&scoped=true&"
import script from "./AppCategoryItem.vue?vue&type=script&lang=js&"
export * from "./AppCategoryItem.vue?vue&type=script&lang=js&"
import style0 from "./AppCategoryItem.vue?vue&type=style&index=0&id=bcf6b394&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcf6b394",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
