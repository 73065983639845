<template>
  <div class="feed-categories-modal-info" :class="{ 'feed-categories-modal-info--single': !category }">
    <div class="feed-categories-modal-info__column" v-if="category">
      <div class="feed-categories-modal-info__text">
        {{ productName }} в фидах поставщиков размещен в {{ productCategory }}
      </div>
    </div>
    <div class="feed-categories-modal-info__column">
      <v-text-field v-model="_search" placeholder="Найти категорию" append-icon="mdi-magnify" hide-details outlined dense />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    search: { type: String, default: null },
  },
  computed: {
    ...mapState('modal', ['context']),
    _search: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    category() {
      return this.context?.category ?? null;
    },
    productName() {
      return this.context.product.name;
    },
    productCategory() {
      return this.context.product.category_name;
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-categories-modal-info {
  --template-columns: 1fr 280px;

  padding: 0 16px 16px;
  display: grid;
  grid-template-columns: var(--template-columns);
  grid-gap: 16px;

  &--single {
    --template-columns: 1fr;
  }

  &__text {
    color: hsl(0, 0%, 0%, 48%);
    font-size: 14px;
  }
}
</style>
