<template>
  <tr class="feed-item-row" :class="{ 'feed-item-row--active': itemIsActive, 'feed-item-row--excluded': itemExcluded }" @click="toggleItem" @mouseover="hover = true" @mouseleave="hover = false">
    <td>
      <v-checkbox :input-value="itemIsActive" color="black" @change="toggleItem" />
    </td>
    <td>
      <router-link class="text-decoration-none" :to="`/products/${item.gmid}/`">{{ item.gmid }}</router-link>
    </td>
    <td>
      <FeedItemExternalId :item="item" />
    </td>
    <td>
      <TextWithPopoverImage :index="index" :name="item.product.name" :src="item.product.image" @click.native="handleClick" />
    </td>
    <td class="feed-item-unit" v-text="item.product.unit_localized" />
    <td class="feed-item-stock" v-text="itemStock" />
    <td>
      <FeedItemPrice :item="item" />
    </td>
    <td>
      <FeedItemStatus :item="item" :index="index" />
    </td>
    <td>
      <FeedItemCategory :item="item" />
    </td>
    <td>
      <FeedItemActions :item="item" class="feed-item-row__actions" :class="{ 'feed-item-row__actions--active': hover }" />
    </td>
  </tr>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import TextWithPopoverImage from '@/components/common/TextWithPopoverImage';
import FeedItemExternalId from '@/components/Feed/FeedItemsTable/FeedItemExternalId';
import FeedItemPrice from '@/components/Feed/FeedItemsTable/FeedItemPrice';
import FeedItemStatus from '@/components/Feed/FeedItemsTable/FeedItemStatus';
import FeedItemCategory from '@/components/Feed/FeedItemsTable/FeedItemCategory';
import FeedItemActions from '@/components/Feed/FeedItemsTable/FeedItemActions';

export default {
  components: {
    TextWithPopoverImage,
    FeedItemExternalId,
    FeedItemPrice,
    FeedItemStatus,
    FeedItemCategory,
    FeedItemActions,
  },
  props: {
    item: { type: Object, required: true },
    index: { type: Number, required: true },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    ...mapState('feeds', ['selectedFeedItems']),
    ...mapGetters('feeds', ['listingId']),
    itemId() {
      return this.item.id;
    },
    feedId() {
      return this.$route.params.feedId;
    },
    itemStock() {
      return this.item.product.stats?.total_stock ?? '-';
    },
    itemIsActive() {
      return this.selectedFeedItems.includes(this.itemId);
    },
    itemExcluded() {
      return this.item.inactive_listings.includes(this.listingId);
    },
  },
  methods: {
    ...mapMutations('feeds', ['TOGGLE_SELECTED_FEED_ITEMS']),
    toggleItem(event) {
      const classes = ['feed-item-name', 'feed-item-unit', 'feed-item-stock', 'feed-item-price', 'input'];
      const shouldToggle = classes.some((item) => event.target?.className.includes(item));

      if (!shouldToggle) return;

      this.TOGGLE_SELECTED_FEED_ITEMS(this.itemId);
    },
    handleClick() {
      this.$router.push(`/feeds/${this.feedId}/${this.itemId}/`);
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-item-row {
  background-color: hsla(0, 0%, 98%, 1);
  transition: background-color 0.1s;
  cursor: pointer;

  &--excluded {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &--active {
    background-color: hsl(227, 100%, 55%, 8%);
  }

  td {
    padding: 0 6px !important; // trying to overwrite vuetify table styling
  }

  &__actions {
    opacity: 0;
    visibility: hidden;

    &--active {
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
