<template>
  <v-dialog v-model="isVisible" width="840px" scrollable>
    <div class="feed-categories-modal">
      <div class="my-15 text-center" v-if="isLoading">
        <v-progress-circular size="64" indeterminate />
      </div>
      <template v-else>
        <FeedCategoriesModalHeader @close="beforeClose" />
        <FeedCategoriesModalInfo v-model="search" />
        <FeedCategoriesModalBody v-model="activeCategoryId" :search="search" />
        <FeedCategoriesModalFooter v-model="activeCategoryId" @close="beforeClose" />
      </template>
    </div>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

import FeedCategoriesModalHeader from '@/components/Feed/FeedCategoriesModal/FeedCategoriesModalHeader';
import FeedCategoriesModalInfo from '@/components/Feed/FeedCategoriesModal/FeedCategoriesModalInfo';
import FeedCategoriesModalBody from '@/components/Feed/FeedCategoriesModal/FeedCategoriesModalBody';
import FeedCategoriesModalFooter from '@/components/Feed/FeedCategoriesModal/FeedCategoriesModalFooter';

export default {
  components: {
    FeedCategoriesModalHeader,
    FeedCategoriesModalInfo,
    FeedCategoriesModalBody,
    FeedCategoriesModalFooter,
  },
  data() {
    return {
      modalName: 'FeedCategoriesModal',
      isLoading: false,
      activeCategoryId: null,
      search: null,
    };
  },
  computed: {
    ...mapState('modal', ['component', 'context']),
    ...mapGetters('feeds', ['marketplaceSlug']),
    isVisible: {
      get() {
        return this.modalName === this.component;
      },
      set() {
        this.beforeClose();
      },
    },
  },
  watch: {
    async isVisible(val) {
      if (!val) return;

      await this.beforeOpen();
    },
  },
  methods: {
    ...mapActions('feeds', ['FETCH_FEED_CATEGORIES']),
    ...mapMutations('modal', ['HIDE_MODAL']),
    async loadCategories() {
      this.isLoading = true;

      try {
        await this.FETCH_FEED_CATEGORIES(this.marketplaceSlug);
      } catch (e) {
        if (!e.isAxiosError) throw e;
      } finally {
        this.isLoading = false;
      }
    },
    async beforeOpen() {
      await this.loadCategories();
    },
    beforeClose() {
      this.activeCategoryId = null;
      this.search = null;
      this.$emit('close');
      this.HIDE_MODAL();
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-categories-modal {
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
}
</style>
