<template>
  <v-row justify="end" algin="center">
    <v-col cols="4">
      <QueryFilterObserver v-bind="filters.search" />
    </v-col>
    <v-col cols="2">
      <QueryFilterObserver v-bind="filters.externalIdStatus" />
    </v-col>
    <v-col cols="2">
      <QueryFilterObserver class="mt-0" v-bind="filters.disabled" />
    </v-col>
    <v-col cols="2">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <QueryFilterObserver class="mt-0" v-bind="filters.allowedToListing" />
          </div>
        </template>
        <span class="d-block"> Товары
          <ul>
            <li>C картинкой</li>
            <li>C вендором</li>
            <li>В наличии или под заказ</li>
            <li>Без категории</li>
          </ul>
        </span>
      </v-tooltip>
    </v-col>
    <v-col cols="2" v-if="isSuperuser">
      <QueryFilterObserver class="mt-0" v-bind="filters.withManualRetailPrices" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import QueryFilterObserver from '@/components/QueryFilterObserver';

export default {
  components: {
    QueryFilterObserver,
  },
  computed: {
    ...mapGetters('auth', ['isSuperuser']),
    ...mapGetters('feeds', ['listingId']),
    filters() {
      return {
        search: {
          component: 'v-text-field',
          name: 'search',
          attrs: {
            label: 'Поиск по товарам',
            clearable: true,
          },
        },
        externalIdStatus: {
          component: 'v-select',
          name: 'external_id_status',
          attrs: {
            label: 'Внешний ID',
            items: [
              { text: 'Есть', value: 'with_external_id' },
              { text: 'Нет', value: 'without_external_id' },
            ],
            clearable: true,
          },
        },
        disabled: {
          component: 'v-checkbox',
          name: 'is_disabled',
          attrs: {
            label: 'Неактивные',
            'false-value': null,
            'true-value': this.listingId,
          },
        },
        allowedToListing: {
          component: 'v-checkbox',
          name: 'is_allowed_to_listing',
          attrs: {
            label: 'Необходима категория',
            'false-value': null,
            'true-value': true,
          },
        },
        withManualRetailPrices: {
          component: 'v-checkbox',
          name: 'with_manual_retail_prices',
          attrs: {
            label: 'C ручной РРЦ',
            'false-value': null,
            'true-value': true,
          },
        },
      };
    },
  },
};
</script>
