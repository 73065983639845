<template>
  <v-simple-table class="feed-items" v-if="items_count">
    <template v-slot:default>
      <thead>
        <tr class="feed-items__row">
          <th class="feed-items__head-col">
            <v-checkbox v-model="toggleAllItems" color="black" />
          </th>
          <th class="feed-items__head-col">GMID</th>
          <th class="feed-items__head-col" width="120px">Внешний артикул</th>
          <th class="feed-items__head-col">
            <div class="feed-items__sort" @click="sortByName">
              Название

              <v-icon small v-if="isSortedByName">mdi-chevron-down</v-icon>
            </div>
          </th>
          <th class="feed-items__head-col" width="80px">Ед. изм.</th>
          <th class="feed-items__head-col">Остаток</th>
          <th class="feed-items__head-col" width="110px">Цена (ритейл)</th>
          <th class="feed-items__head-col" width="140px">Статус</th>
          <th class="feed-items__head-col" width="190px">Категория</th>
          <th class="feed-items__head-col" width="40px">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <FeedItemRow v-for="(item, index) in items" :key="item.id" :item="item" :index="index" />
      </tbody>
    </template>
  </v-simple-table>
  <div v-else>Кажется тут пусто ;(</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import FeedItemRow from '@/components/Feed/FeedItemsTable/FeedItemRow';

export default {
  components: {
    FeedItemRow,
  },
  computed: {
    ...mapState('feeds', ['items', 'items_count', 'selectedFeedItems']),
    isSortedByName() {
      return typeof this.$route.query?.ordering === 'string';
    },
    toggleAllItems: {
      get() {
        return this.items.every((item) => this.selectedFeedItems.includes(item.id));
      },
      set(val) {
        const itemsIds = this.items.map((item) => item.id);
        let nextItems = null;

        if (!val) {
          nextItems = this.selectedFeedItems.filter((selectedItem) => !itemsIds.includes(selectedItem));
        } else {
          nextItems = new Set([
            ...this.selectedFeedItems,
            ...itemsIds,
          ]);
        }

        this.SET_SELECTED_FEED_ITEMS([...nextItems]);
      },
    },
  },
  methods: {
    ...mapMutations('feeds', ['SET_SELECTED_FEED_ITEMS']),
    sortByName() {
      this.$router.push({
        query: {
          ...this.$route.query,
          ordering: this.isSortedByName ? null : 'product__name',
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-items {
  &__head-col {
    padding: 0 6px !important; // trying to overwrite vuetify table styling
    border-top: 1px solid hsl(0, 0%, 0%, 12%);
  }

  &__sort {
    cursor: pointer;
  }
}
</style>
