<template>
  <div class="feed-item-status" @click="isEditing = true">
    <div class="feed-item-status__text">
      {{ item.status_localized }}
    </div>
    <div class="feed-item-status__button">
      <v-icon small>mdi-pencil</v-icon>
    </div>

    <div :class="dropdownClasses" v-click-outside="onClickOutside" v-if="isEditing">
      <v-radio-group v-model="_value" hide-details dense>
        <v-radio v-for="(status, idx) in statuses" :key="idx" :value="status.value" :disabled="!item.external_id && status.value === 'confirmed'">
          <template v-slot:label>
            <div class="feed-item-status__label" v-text="status.label" />
          </template>
        </v-radio>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    item: { type: Object, required: true },
    index: { type: Number, required: true },
  },
  data() {
    return {
      isEditing: false,
      statuses: [
        { label: 'Размещен', value: 'confirmed' },
        { label: 'Не размещен', value: 'pending_confirmation' },
      ],
    };
  },
  computed: {
    _value: {
      get() {
        return this.item.status;
      },
      async set(val) {
        await this.SET_FEED_ITEM_STATUS({
          listingId: this.feedId,
          itemId: this.item.id,
          status: val,
        });
        this.isEditing = false;
      },
    },
    feedId() {
      return this.$route.params.feedId;
    },
    dropdownClasses() {
      return {
        'feed-item-status__dropdown': true,
        'feed-item-status__dropdown--position-top': this.index > 3,
        'feed-item-status__dropdown--position-bottom': this.index <= 3,
      };
    },
  },
  methods: {
    ...mapActions('feeds', ['SET_FEED_ITEM_STATUS']),
    onClickOutside() {
      if (!this.isEditing) return;

      this.isEditing = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-item-status {
  --button-display: none;

  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    --button-display: unset;
  }

  &__text {
    margin-right: 6px;
  }

  &__button {
    display: var(--button-display);
  }

  &__dropdown {
    padding: 10px;
    position: absolute;
    left: 0;
    width: 160px;
    background-color: hsl(0, 0%, 100%);
    border: 1px solid hsl(0, 0%, 0%, 8%);
    box-shadow: 0 2px 8px hsl(0, 0%, 0%, 16%);
    border-radius: 4px;
    z-index: var(--z-index);

    &--position-top {
      bottom: 100%;
    }

    &--position-bottom {
      top: 100%;
    }

    &::v-deep .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &__label {
    font-size: 14px;
  }
}
</style>
