<template>
  <div class="feed-item-external-id">
    <template v-if="!isEditing">
      <v-tooltip bottom open-delay="300">
        <template v-slot:activator="{ on, attrs }">
          <div class="feed-item-external-id__text" :class="{ 'feed-item-external-id__text--highlighted': item.external_id }" @click="openOnWebsite" v-bind="attrs" v-on="on">
            {{ item.external_id || '-' }}
          </div>
        </template>
        <span>Открыть этот товар на маркетплейсе</span>
      </v-tooltip>

      <v-tooltip bottom open-delay="300">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="feed-item-external-id__button" @click="editMode" v-bind="attrs" v-on="on" small v-if="itemListingsIds.length">mdi-pencil</v-icon>
        </template>
        <span>Редактировать</span>
      </v-tooltip>
    </template>

    <template v-else>
      <v-tooltip :value="!isConfirmed" max-width="210px" top>
        <template v-slot:activator="{ on, attrs }">
          <div class="feed-item-external-id__field" v-click-outside="onClickOutside">
            <AppPlainInput class="feed-item-external-id__input" v-bind="attrs" v-on="on" v-model="nextVal" :disabled="isSubmitting" @keyup.enter.native="submit" @keyup.esc.native="isEditing = false" />
            <v-icon class="feed-item-external-id__delete" @click="deleteItem" small>mdi-delete</v-icon>
          </div>
        </template>
        <span>Укажите внешний артикул, чтобы разместить товар</span>
      </v-tooltip>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import AppPlainInput from '@/components/AppPlainInput';

export default {
  components: {
    AppPlainInput,
  },
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      isEditing: false,
      isSubmitting: false,
      nextVal: null,
    };
  },
  computed: {
    ...mapGetters('feeds', ['marketplaceSlug']),
    _marketplaceSlug() {
      return this.item.marketplace?.slug ?? this.marketplaceSlug;
    },
    feedId() {
      return this.$route.params.feedId;
    },
    itemHasMultipleListings() {
      return this.item.listings?.length;
    },
    itemListingsIds() {
      if (!this.itemHasMultipleListings) return this.feedId || null;

      return this.item.listings.map((listing) => listing.id);
    },
    isConfirmed() {
      return this.item.status === 'confirmed';
    },
    redirectUrls() {
      return {
        gdematerial: `https://gdematerial.ru/model/${this.item.external_id}/`,
        isolux: `https://www.isolux.ru/catalogsearch/result/index/?q=${this.item.external_id}`,
      };
    },
  },
  methods: {
    ...mapActions('feeds', ['SET_FEED_ITEM_EXTERNAL_ID', 'DESTROY_FEED_ITEM']),
    editMode() {
      this.isEditing = true;
      this.nextVal = this.item.external_id;
    },
    openOnWebsite() {
      if (!this.item.external_id) return this.editMode();

      const redirectTo = this.redirectUrls[this._marketplaceSlug];

      if (!redirectTo) return;

      window.open(redirectTo, '_blank');
    },
    onClickOutside() {
      if (!this.isEditing) return;

      this.isEditing = false;
    },
    async submit() {
      if (!this.nextVal.trim().length) {
        this.$notify.showMessage({ message: 'Вы не можете сохранить пустое значение', color: 'error' });
        return;
      }

      this.isSubmitting = true;

      try {
        await Promise.all(this.itemListingsIds.map((listingId) => this.SET_FEED_ITEM_EXTERNAL_ID({
          itemId: this.item.id,
          externalId: this.nextVal,
          listingId,
        })));

        this.$emit('change');
        this.$notify.showMessage({ message: 'Внешний артикул обновлен.', color: 'success' });
        this.isEditing = false;
      } catch (e) {
        this.$notify.showErrorMessage(e.response);
        if (!e.isAxiosError) throw e;
      } finally {
        this.isSubmitting = false;
      }
    },
    async deleteItem() {
      const confirmed = await this.$root.$confirm('Удаление товара', `При удалении ${this.item.external_id || ''} товар будет безвозвратно удален из всех листингов этого маркетплейса. Уверены?`);

      if (!confirmed) return;

      this.isSubmitting = true;

      try {
        await Promise.allSettled(this.itemListingsIds.map((listingId) => this.DESTROY_FEED_ITEM({
          itemId: this.item.id,
          listingId,
        })));

        this.$notify.showMessage({ message: 'Товар успешно удален.', color: 'success' });
        this.$emit('change');
      } catch (e) {
        this.$notify.showMessage({ message: 'Что-то пошло не так, попробуйте еще раз.', color: 'error' });
        if (!e.isAxiosError) throw e;
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-item-external-id {
  --button-display: none;

  display: flex;
  align-items: center;

  &:hover {
    --button-display: unset;
  }

  &__text {
    margin-right: 6px;
    cursor: pointer;

    &--highlighted {
      color: var(--primary-color);
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom-color: var(--primary-color);
      }
    }
  }

  &__button {
    display: var(--button-display);
  }

  &__field {
    position: relative;
  }

  &__delete {
    position: absolute;
    top: 8px;
    right: 4px;
  }

  &__input {
    &::v-deep input {
      padding-right: 24px;
    }
  }
}
</style>
