<template>
  <div class="feed-categories-modal-body">
    <div class="feed-categories-modal-body__categories" v-if="filteredCategories.length">
      <AppCategoriesList v-for="(column, columnIdx) in columns" :key="columnIdx">
        <AppCategoryItem v-for="item in column.submenu" :key="item.id" :category="item" :columns="columns" :column-idx="columnIdx" :active-category-id="activeCategoryId" @select="selectCategory" />
      </AppCategoriesList>
    </div>

    <div class="feed-categories-modal-body__empty" v-else>
      Категории не найдены
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import AppCategoriesList from '@/components/AppCategories/AppCategoriesList';
import AppCategoryItem from '@/components/AppCategories/AppCategoryItem';

export default {
  components: {
    AppCategoriesList,
    AppCategoryItem,
  },
  props: {
    value: { type: Number, default: null },
    search: { type: String, default: null },
  },
  data() {
    return {
      columns: [],
      columnsLimit: 5,
    };
  },
  computed: {
    ...mapState('modal', ['context']),
    ...mapGetters('feeds', ['getCategories', 'getSelectedCategories', 'filterCategoriesByName']),
    activeCategoryId: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    filteredCategories() {
      if (this.search) return this.filterCategoriesByName(this.search);

      return this.getCategories;
    },
    category() {
      return this.context?.category ?? null;
    },
    rootCategory() {
      return {
        submenu: this.filteredCategories,
      };
    },
  },
  watch: {
    search() {
      this.createColumns();

      if (!this.search?.length) this.createColumnsBySelectedCategory();
    },
  },
  mounted() {
    this.createColumns();
    this.createColumnsBySelectedCategory();
  },
  methods: {
    createColumnsBySelectedCategory() {
      if (!this.category) return;

      this.activeCategoryId = this.category.id;
      const categories = this.getSelectedCategories(this.category.id);

      this.columns = [...this.columns, ...categories];
    },
    createColumns() {
      this.columns = [this.rootCategory];
    },
    selectCategory({ columnIdx, category }) {
      const startColumnIdx = columnIdx + 1;
      const deleteColumnsCount = this.columnsLimit - startColumnIdx;

      this.activeCategoryId = category.id;

      if (deleteColumnsCount < 1) return;

      if (category.submenu.length) {
        this.columns.splice(startColumnIdx, deleteColumnsCount, category);
      } else {
        this.columns.splice(startColumnIdx);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-categories-modal-body {
  padding: 8px;
  border-top: 1px solid hsl(0, 0%, 0%, 8%);
  border-bottom: 1px solid hsl(0, 0%, 0%, 8%);
  overflow-x: auto;
  overflow-y: auto;

  &__categories {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(194px, 1fr));
    grid-auto-columns: minmax(194px,1fr);
    grid-auto-flow: column;
    height: 100%;
    overflow-x: auto;
  }

  &__empty {
    padding: 48px;
    color: hsl(0, 0%, 30%);
    text-align: center;
  }
}
</style>
