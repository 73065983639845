<template>
  <div class="feed-item-actions">
    <AppButton theme="primary-light" :disabled="deleting" @click.native="deleteItem">
      <v-icon color="primary" small>mdi-delete</v-icon>
    </AppButton>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import AppButton from '@/components/AppButton';

export default {
  components: {
    AppButton,
  },
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    feedId() {
      return this.$route.params.feedId;
    },
  },
  methods: {
    ...mapActions('feeds', ['DELETE_FEED_ITEM']),
    async deleteItem() {
      if (!window.confirm('Вы действительно хотите удалить позицию с фида?')) return;

      this.deleting = true;

      try {
        await this.DELETE_FEED_ITEM({
          listingId: this.feedId,
          itemId: this.item.id,
        });
        this.$notify.showMessage({ message: 'Позиция успешно удалена.', color: 'success' });
      } catch (e) {
        this.$notify.showMessage({ message: 'Что-то пошло не так, попробуйте еще раз.', color: 'error' });
        if (!e.isAxiosError) throw e;
      } finally {
        this.deleting = false;
      }
    },
  },
};
</script>
