<template>
  <div class="feed-categories-modal-footer">
    <AppButton class="feed-categories-modal-footer__button" text="Сохранить" :disabled="!value || isSubmitting" @click.native="conditionalSubmission" />
    <AppButton class="feed-categories-modal-footer__button" text="Удалить категорию" theme="danger" :disabled="isRemoving" @click.native="remove" v-if="category" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

import AppButton from '@/components/AppButton';

export default {
  components: {
    AppButton,
  },
  props: {
    value: { type: Number, default: null },
  },
  data() {
    return {
      isSubmitting: false,
      isRemoving: false,
    };
  },
  computed: {
    ...mapState('modal', ['context']),
    ...mapGetters('feeds', ['listingId']),
    category() {
      return this.context?.category ?? null;
    },
    shouldUpdateForMultipleItems() {
      return this.context?.action === 'bulkUpdate';
    },
  },
  methods: {
    ...mapActions('feeds', ['SET_CATEGORY_FOR_FEED_ITEM', 'DROP_CATEGORY_FOR_FEED_ITEM', 'BULK_SET_CATEGORY_FOR_FEED_ITEMS']),
    ...mapMutations('feeds', ['SET_SELECTED_FEED_ITEMS']),
    async conditionalSubmission() {
      if (this.shouldUpdateForMultipleItems) {
        await this.submitForMultipleItems();
      } else {
        await this.submit();
      }
    },
    async submit() {
      this.isSubmitting = true;

      try {
        await this.SET_CATEGORY_FOR_FEED_ITEM({
          listingId: this.listingId,
          itemId: this.context.id,
          categoryId: this.value,
        });
        this.$emit('close');
        this.$notify.showMessage({ message: 'Категория успешно установлена.', color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
      } finally {
        this.isSubmitting = false;
      }
    },
    async remove() {
      this.isRemoving = true;

      try {
        await this.DROP_CATEGORY_FOR_FEED_ITEM({
          listingId: this.listingId,
          itemId: this.context.id,
        });
        this.$emit('close');
        this.$notify.showMessage({ message: 'Категории успешно удалена.', color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
      } finally {
        this.isRemoving = false;
      }
    },
    async submitForMultipleItems() {
      this.isSubmitting = true;

      try {
        await this.BULK_SET_CATEGORY_FOR_FEED_ITEMS({
          listingId: this.listingId,
          categoryId: this.value,
          items: this.context.items,
        });
        this.SET_SELECTED_FEED_ITEMS([]);
        this.$emit('close');
        this.$notify.showMessage({ message: 'Категории успешно установлены.', color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-categories-modal-footer {
  display: flex;
  justify-content: center;
  padding: 12px;

  &__button {
    font-size: 12px;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}
</style>
