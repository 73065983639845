var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"feed-item-external-id"},[(!_vm.isEditing)?[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"feed-item-external-id__text",class:{ 'feed-item-external-id__text--highlighted': _vm.item.external_id },on:{"click":_vm.openOnWebsite}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.item.external_id || '-')+" ")])]}}],null,false,27626477)},[_c('span',[_vm._v("Открыть этот товар на маркетплейсе")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.itemListingsIds.length)?_c('v-icon',_vm._g(_vm._b({staticClass:"feed-item-external-id__button",attrs:{"small":""},on:{"click":_vm.editMode}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")]):_vm._e()]}}],null,false,3293148199)},[_c('span',[_vm._v("Редактировать")])])]:[_c('v-tooltip',{attrs:{"value":!_vm.isConfirmed,"max-width":"210px","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"feed-item-external-id__field"},[_c('AppPlainInput',_vm._g(_vm._b({staticClass:"feed-item-external-id__input",attrs:{"disabled":_vm.isSubmitting},nativeOn:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.isEditing = false}]},model:{value:(_vm.nextVal),callback:function ($$v) {_vm.nextVal=$$v},expression:"nextVal"}},'AppPlainInput',attrs,false),on)),_c('v-icon',{staticClass:"feed-item-external-id__delete",attrs:{"small":""},on:{"click":_vm.deleteItem}},[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Укажите внешний артикул, чтобы разместить товар")])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }