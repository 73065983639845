<template>
  <div class="feed-item-price" :class="{ 'feed-item-price--no-price': !price }">
    <AppPrice :price="price" :currency="item.currency" v-if="price" />
    <template v-else>
      {{ formattedPrice }}
    </template>
  </div>
</template>

<script>
import AppPrice from '@/components/AppPrice';

export default {
  components: {
    AppPrice,
  },
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    price() {
      return this.item.retail_price;
    },
    formattedPrice() {
      if (!this.price) return 'под заказ';

      return `${this.price} ₽`;
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-item-price {
  display: flex;
  align-items: center;
  height: 100%;

  &--no-price {
    font-size: 13px;
  }
}
</style>
