<template>
  <div class="app-plain-input">
    <input class="app-plain-input__field" v-model="_value" :type="type" v-bind="$attrs">
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String, default: null },
    type: { type: String, default: 'type' },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.app-plain-input {
  &__field {
    padding: 0 5px;
    width: 100%;
    height: 32px;
    background: hsl(0, 0%, 100%);
    border-radius: 4px;
    border: 1px solid hsl(227, 100%, 55%, 30%);
    outline: 0;
  }
}
</style>
