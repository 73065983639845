<template>
  <div class="feed">
    <v-col cols="12" v-if="hasError">
      <h1>404</h1>
    </v-col>

    <v-col class="my-15 text-center" cols="12" v-else-if="isLoading">
      <v-progress-circular size="32" indeterminate />
    </v-col>

    <template v-else>
      <v-col cols="12">
        <div class="feed__header">
          <div class="feed__title">
            {{ name }}
          </div>
          <div class="feed__stats">
            товаров: {{ items_count }}
          </div>

          <FeedFilters />
        </div>
      </v-col>

      <v-col cols="12">
        <FeedItems />
      </v-col>

      <v-row class="mb-16 mt-8" v-if="pageCount > 1">
        <v-col offset="4" cols="4">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7" @input="scrollToTop" />
        </v-col>
        <v-col offset="3" cols="1">
          <v-select v-model="itemsPerPage" label="Количество строк" :items="itemsPerPageSelect" :menu-props="{ top: true, offsetY: true }" outlined dense />
        </v-col>
      </v-row>

      <FeedCategoriesModal />
      <FeedSelectedItemsActions v-if="selectedFeedItems.length" />
    </template>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState, mapActions } from 'vuex';

import PaginationMixin from '@/mixins/PaginationMixin';

import FeedFilters from '@/components/Feed/FeedFilters';
import FeedItems from '@/components/Feed/FeedItems';
import FeedCategoriesModal from '@/components/Feed/FeedCategoriesModal/FeedCategoriesModal';
import FeedSelectedItemsActions from '@/components/Feed/FeedSelectedItemsActions';

export default {
  components: {
    FeedFilters,
    FeedItems,
    FeedCategoriesModal,
    FeedSelectedItemsActions,
  },
  mixins: [PaginationMixin],
  data() {
    return {
      itemsPerPageSelect: [24, 48, 72, 96, 120, 144],
      isLoading: false,
      hasError: false,
    };
  },
  metaInfo() {
    return {
      title: `${this.name} — Инвентарь`,
    };
  },
  computed: {
    ...mapState('feeds', ['listing', 'items_count', 'selectedFeedItems']),
    feedId() {
      return this.$route.params.feedId;
    },
    name() {
      return [this.listing?.marketplace?.name, this.listing?.name].join(': ') ?? '';
    },
    pageCount() {
      return Math.ceil(this.items_count / this.itemsPerPage);
    },
    itemsPerPage: {
      get() {
        return Number(this.$route.query.page_size) || 24;
      },
      set(val) {
        this.$router.push({
          query: {
            ...this.$route.query,
            page_size: val,
          },
        });
      },
    },
  },
  watch: {
    feedId: {
      immediate: true,
      async handler(feedId) {
        this.isLoading = true;
        this.hasError = false;

        try {
          await Promise.all([
            this.FETCH_LISTING(feedId),
            this.FETCH_ITEMS({
              listingId: this.feedId,
              params: {
                page_size: this.itemsPerPage,
                ...this.$route.query,
              },
            }),
          ]);
        } catch (e) {
          this.hasError = true;
          this.$notify.showErrorMessage(e.response);
          if (!e.isAxiosError) throw e;
        } finally {
          this.isLoading = false;
        }
      },
    },
    '$route.query': {
      handler: debounce(async function (params) {
        this.isLoading = true;

        try {
          await this.FETCH_ITEMS({
            listingId: this.feedId,
            params: {
              page_size: this.itemsPerPage,
              ...params,
            },
          });
          this.isLoading = false;
        } catch (e) {
          this.isLoading = e.isAxiosCancelError;
          if (!e.isAxiosCancelError) this.$notify.showErrorMessage(e.response);
          if (!e.isAxiosError && !e.isAxiosCancelError) throw e;
        }
      }, 600),
    },
  },
  methods: {
    ...mapActions('feeds', ['FETCH_LISTING', 'FETCH_ITEMS']),
    scrollToTop() {
      this.$scrollTo('body');
    },
  },
};
</script>

<style lang="scss" scoped>
.feed {
  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    margin-right: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  &__stats {
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
