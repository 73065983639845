<template>
  <button class="category-item" :class="{ 'category-item--active': categoryIsActive }" type="button" @click="onCategorySelect">
    <span>{{ category.name }}</span>
    <v-icon class="category-item__icon" v-if="category.submenu.length">mdi-chevron-right</v-icon>
  </button>
</template>

<script>
export default {
  props: {
    category: { type: Object, required: true },
    columns: { type: Array, required: true },
    columnIdx: { type: Number, required: true },
    activeCategoryId: { type: [String, Number], default: null },
  },
  computed: {
    categoryId() {
      return this.category.id || null;
    },
    categoryIsActive() {
      const categoryColumn = this.columns[this.columnIdx + 1];

      if (this.activeCategoryId === this.categoryId) return true;

      return categoryColumn ? categoryColumn.id === this.categoryId : false;
    },
  },
  methods: {
    onCategorySelect() {
      if (this.categoryIsActive) return;

      this.$emit('select', { category: this.category, columnIdx: this.columnIdx });
    },
  },
};
</script>

<style lang="scss" scoped>
.category-item {
  --icon-color: inherit;

  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
  background-color: transparent;
  border-radius: 4px;
  border: 0;
  outline: 0;
  font-size: 13px;
  text-align: left;

  &:hover,
  &--active {
    --icon-color: hsl(0, 0%, 100%);

    background-color: hsl(230, 78%, 51%);
    color: hsl(0, 0%, 100%);
  }

  &__icon {
    margin-left: auto;
    color: var(--icon-color);
  }
}
</style>
