<template>
  <FloatingActionsBar :selected-items="selectedFeedItems" confirm-button-text="Выполнить" :disabled="isDisabled" @confirm="processOperations" @close="clearAll">
    <div class="feed-selected-items-actions">
      <v-select class="feed-selected-items-actions__select" v-model="operation" :items="operations" placeholder="Выберите операцию" single-line dense outlined hide-details />
    </div>
  </FloatingActionsBar>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

import FloatingActionsBar from '@/components/FloatingActionsBar';

export default {
  components: {
    FloatingActionsBar,
  },
  data() {
    return {
      operation: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('feeds', ['selectedFeedItems']),
    ...mapGetters('feeds', ['listingId']),
    isDisabled() {
      return this.isLoading || !this.operation;
    },
    operations() {
      return [
        { text: 'Назначить категорию', value: 'category' },
        { text: 'Удаление товаров из листинга', value: 'remove' },
        { text: 'Сделать активными', value: 'activate' },
        { text: 'Сделать неактивными', value: 'deactivate' },
      ];
    },
    feedId() {
      return this.$route.params.feedId;
    },
  },
  methods: {
    ...mapActions('feeds', ['DELETE_FEED_ITEM', 'ACTIVATE_FEED_ITEM', 'DEACTIVATE_FEED_ITEM']),
    ...mapMutations('feeds', ['SET_SELECTED_FEED_ITEMS']),
    ...mapMutations('modal', ['SHOW_MODAL']),
    clearAll() {
      this.SET_SELECTED_FEED_ITEMS([]);
    },
    bulkSetCategories() {
      this.SHOW_MODAL({
        component: 'FeedCategoriesModal',
        context: {
          action: 'bulkUpdate',
          items: this.selectedFeedItems,
        },
      });
    },
    async bulkRemoveItems() {
      if (!window.confirm('Вы действительно хотите удалить выбранные позиции с фида?')) return;

      this.isLoading = true;

      try {
        await Promise.all(this.selectedFeedItems.map((itemId) => this.DELETE_FEED_ITEM({
          listingId: this.feedId,
          itemId,
        })));
        this.clearAll();
        this.$notify.showMessage({ message: 'Выбранные позиции успешно удалены.', color: 'success' });
      } catch (e) {
        this.$notify.showMessage({ message: 'Что-то пошло не так, попробуйте еще раз.', color: 'error' });
        if (!e.isAxiosError) throw e;
      } finally {
        this.isLoading = false;
      }
    },
    async bulkActivateItems() {
      if (!window.confirm('Активировать выбранные позиции?')) return;

      this.isLoading = true;

      try {
        await Promise.all(this.selectedFeedItems.map((itemId) => this.ACTIVATE_FEED_ITEM({
          listingId: this.listingId,
          itemId,
        })));
        this.clearAll();
        this.$notify.showMessage({ message: 'Выбранные позиции успешно активированы.', color: 'success' });
      } catch (e) {
        this.$notify.showMessage({ message: 'Что-то пошло не так, попробуйте еще раз.', color: 'error' });
        if (!e.isAxiosError) throw e;
      } finally {
        this.isLoading = false;
      }
    },
    async bulkDeactivateItems() {
      if (!window.confirm('Деактивировать выбранные позиции?')) return;

      this.isLoading = true;

      try {
        await Promise.all(this.selectedFeedItems.map((itemId) => this.DEACTIVATE_FEED_ITEM({
          listingId: this.listingId,
          itemId,
        })));
        this.clearAll();
        this.$notify.showMessage({ message: 'Выбранные позиции успешно деактивированы.', color: 'success' });
      } catch (e) {
        this.$notify.showMessage({ message: 'Что-то пошло не так, попробуйте еще раз.', color: 'error' });
        if (!e.isAxiosError) throw e;
      } finally {
        this.isLoading = false;
      }
    },
    processOperations() {
      const operationBindings = {
        category: this.bulkSetCategories,
        remove: this.bulkRemoveItems,
        activate: this.bulkActivateItems,
        deactivate: this.bulkDeactivateItems,
      };

      if (this.operation in operationBindings) {
        return operationBindings[this.operation]();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-selected-items-actions {
  &__select {
    max-width: 220px;
  }
}
</style>
