<template>
  <div class="feed-item-category">
    <button class="feed-item-category__button" @click="showCategoriesModal(item)" v-if="item.category">
      {{ item.category.name }}
      <v-icon class="feed-item-category__icon" small>mdi-pencil</v-icon>
    </button>

    <AppButton class="feed-item-category__button" text="Назначить категорию" theme="primary-light" @click.native="showCategoriesModal(item)" v-else />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import AppButton from '@/components/AppButton';

export default {
  components: {
    AppButton,
  },
  props: {
    item: { type: Object, required: true },
  },
  methods: {
    ...mapMutations('modal', ['SHOW_MODAL']),
    getCategoryName() {
      if (!this.item.category) return 'Назначить категорию';

      return this.item.category.name;
    },
    showCategoriesModal() {
      this.SHOW_MODAL({
        component: 'FeedCategoriesModal',
        context: this.item,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-item-category {
  --icon-display: none;

  &:hover {
    --icon-display: unset;
  }

  &__icon {
    display: var(--icon-display);
  }

  &__button {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  &__icon {
    margin-left: 8px;
  }
}
</style>
